<template>
  <v-main class="container-prosker">
    <prosk-title class="buy-title">
      {{ $t('buy') }}
    </prosk-title>
    <div class="intro-text">
      {{ getIntroText(0) }}
      <router-link color="secondary" :to="{ name: 'social.contact-page' }">
        {{ getIntroText(1) }}
      </router-link>,
      {{ getIntroText(2) }}
    </div>

    <v-stepper v-model="buyStep" elevation='0' class="stepper">
      <v-stepper-header class="step-header">
        <v-stepper-step
          class="p-0"
          color="title_highlight_color"
          v-for="(step, index) in steps"
          :key="step.id"
          :complete="buyStep > index"
          :step="index + 1"
          :disabled="index == 1 && selectedService && !selectedService.schedule_required"
          @click="handleStepClick(index + 1, $event)"
        >
          {{ step.title }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items class="stepper-items">
        <v-stepper-content step="1" class="p-0">
         <user-service-cards
            :userId="userId"
            :on-buy-service="onBuyService"
          />
        </v-stepper-content>

        <v-stepper-content step="2" class="p-0">
          <user-service-schedule
            v-if="!!selectedService"
            :userId="userId"
            :selectedService="selectedService"
            @schedule="changedEventDate"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12" md="8">
              <user-service-card-item
                v-if="selectedService"
                :data="selectedService"
                :show-footer="false"
              />
            </v-col>
            <v-col cols="12" md="4">
              <details-card>
                <template v-slot:content>
                  <div class="details-column">
                    <div v-if="selectedDateTime.date">
                      <prosk-icon icon="agenda" class="title_highlight_color--text" />
                      <span class="dark_blue--text font-weight-bold">
                        {{ formatDate(selectedDateTime.date) }}
                      </span>
                    </div>
                    <div v-if="selectedDateTime.time">
                      <prosk-icon icon="hora" class="title_highlight_color--text" />
                      <span class="dark_blue--text font-weight-bold">{{ selectedDateTime.time }}</span>
                    </div>
                    <div>
                      <prosk-icon icon="precio" class="title_highlight_color--text" />
                      <span class="dark_blue--text font-weight-bold">{{ selectedService && formatPrice(selectedService.price) }}</span>
                    </div>
                    <general-button
                      v-if="selectedService && (!selectedService.payment_required) || (currentUser && currentUser.mp_access_token === null)"

                      secondary
                      class="w-100"
                      :action="confirmFreeService"
                      id="confirm-free-btn"
                    >
                      {{ $t('confirm') }}
                    </general-button>
                    <general-button
                      v-else
                      secondary
                      class="w-100"
                      :action="proceedToCheckout"
                      id="confirm-buy-btn-2"
                    >
                      {{ $t('proceedToPay') }}
                    </general-button>
                  </div>
                </template>
              </details-card>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="4" class="p-0">
          <service-checkout :key="checkoutKey" :service="selectedService" :selectedDateTime="selectedDateTime"></service-checkout>
        </v-stepper-content>

        <v-stepper-content step="5" class="p-0" v-if="!!selectedService">
          <div class="thanks-step">
            <prosk-title>
              {{ $t('buyServiceThanks') }}:
            </prosk-title>
            <user-service-card-item
              :data="selectedService"
              :show-footer="false"
            >
              <v-divider />
              <v-card-actions class="thanks-footer dialog-actions dark_blue--text">
                <div class="details-column">
                  <div v-if="selectedDateTime.date">
                    <prosk-icon icon="agenda" class="title_highlight_color--text" />
                    <span class="dark_blue--text font-weight-bold">
                      {{ formatDate(selectedDateTime.date) }}
                    </span>
                  </div>
                  <div v-if="selectedDateTime.time">
                    <prosk-icon icon="hora" class="title_highlight_color--text" />
                    <span class="dark_blue--text font-weight-bold">{{ selectedDateTime.time }}</span>
                  </div>
                  <div>
                    <prosk-icon icon="precio" class="title_highlight_color--text" />
                    <span class="dark_blue--text font-weight-bold">{{ formatPrice(selectedService.price) }}</span>
                  </div>
                  <general-button secondary class="w-100" :action="goToServices">
                    {{ $t('goToServices') }}
                  </general-button>
                </div>
              </v-card-actions>
            </user-service-card-item>
          </div>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-main>
</template>
<script>
import moment from 'moment';

import errorDialog from '@/components/socialvue/mixins/errorDialog';
import userDataMixin from '@/mixins/userDataMixin';
import ProskTitle from '@/components/socialvue/markup/ProskTitle';
import UserServiceCards from './BuyService/UserServiceCards.vue';
import UserServiceSchedule from './BuyService/UserServiceSchedule.vue';
import UserServiceCardItem from './BuyService/UserServiceCardItem.vue';
import DetailsCard from '@/components/socialvue/cards/DetailsCard';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import ProskIcon from '@/icons/ProskIcon.vue';
import { formatPrice } from '@/misc/utils';
import ServiceCheckout from '../AuthPages/Default/ServiceCheckout.vue';
import axios from 'axios';

export default {
  name: 'UserBuyService',
  components: {
    DetailsCard,
    GeneralButton,
    ProskIcon,
    ProskTitle,
    UserServiceCardItem,
    UserServiceCards,
    UserServiceSchedule,
    ServiceCheckout
  },
  props: ['userId'],
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      buyStep: 1,
      steps: [],
      selectedService: undefined,
      selectedDateTime: { date: '', time: '' },
      formattedStartDate: null,
      checkoutKey: 1
    };
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    }
  },
  created () {
    this.setSteps();
  },
  methods: {
    formatPrice,
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY');
    },
    getIntroText (i) {
      return this.$t('buyServiceIntro').split('|')[i];
    },
    setSteps () {
      this.steps = [
        {
          id: 'choose',
          title: this.$t('choose')
        },
        {
          id: 'schedule',
          title: this.$t('schedule')
        },
        {
          id: 'confirm',
          title: this.$t('confirm')
        },
        {
          id: 'pay',
          title: this.$t('pay')
        },
        {
          id: 'finalize',
          title: this.$t('finalize')
        }
      ]
    },
    clearLocalStorageKeys () {
      const keys = ['prosk_startDate', 'prosk_selectedService', 'prosk_selectedTime'];
      if (keys.some(key => localStorage.getItem(key))) {
        keys.forEach(key => localStorage.removeItem(key));
      }
    },
    onBuyService (service) {
      this.selectedService = service;
      localStorage.setItem('prosk_selectedService', JSON.stringify(this.selectedService));
      if (service.schedule_required) {
        this.buyStep = 2;
      } else {
        this.buyStep = 3;
      }
    },
    handleStepClick (step, event) {
      const isDisabled = event.target.closest('.v-stepper__step').getAttribute('disabled');
      if (this.buyStep > step && !isDisabled) {
        this.buyStep = step;
      }
    },
    changedEventDate (dateEvent) {
      this.selectedDateTime = {
        date: dateEvent.date,
        time: dateEvent.time
      };
      localStorage.setItem('prosk_selectedTime', dateEvent.time);
      this.formattedStartDate = moment.utc(dateEvent.date).format('DD-MM-YYYY');
      localStorage.setItem('prosk_startDate', this.formattedStartDate);
      this.buyStep = 3;
    },
    proceedToCheckout () {
      if (!this.currentUser) {
        this.$router.push({
          name: 'auth1.sign-in1'
        });
        return
      }
      this.checkoutKey++; // force remount of service-checkout component
      this.buyStep = 4;
      this.$router.push({
        name: 'checkout-service'
      });
    },
    goToServices () {
      this.$router.push({
        name: 'social.services',
        params: { query: 'servicios-contratados' }
      });
    },
    sendDataLayerInfo () {
        this.trackUserData('load_content', {
        pageType: 'my_account',
        pageSubType: 'buy_service'
      });
    },
    async confirmFreeService () {
      if (!this.currentUser) {
        this.$router.push({
          name: 'auth1.sign-in1'
        });
        return;
      }
      if (!this.selectedService) {
        this.$swal.fire({
          title: this.$t('error'),
          text: this.$t('noServiceSelected'),
          confirmButtonColor: '#FF0000'
        });
        return;
      }
      const serviceId = this.selectedService.id;
      const formData = {
        prosker_user_id: this.userId,
        client_user_id: this.currentUser.id,
        title: this.selectedService.name,
        description: this.selectedService.description,
        prosker_approval: null,
        client_approval: null,
        price: this.selectedService.price,
        start_date: this.formattedStartDate || moment.utc().format('DD-MM-YYYY'),
        payment_required: this.selectedService.payment_required,
        reason_help: null,
        service_id: this.selectedService.id
      };
      const url = `${process.env.VUE_APP_BASE_URL}/api/service/${serviceId}/create-job`;
      try {
        this.loading = true;
        await axios.post(url, formData);
        this.loading = false;

        this.$swal.fire({
          title: this.$t('awesome'),
          text: this.$t('jobCreatedSuccessfully') + (this.currentUser.mp_access_token === null ? '. ' + this.$t('additionalMessageForUser') : ''),
          confirmButtonColor: '#19AD93'
        });
        this.clearLocalStorageKeys();
        this.buyStep = 5;
      } catch (error) {
        this.loading = false;
        this.$swal.fire({
          title: this.$t('error'),
          text: this.$t('jobCreationFailed'),
          confirmButtonColor: '#FF0000'
        });
        console.error('Error creating job:', error);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.buy-title {
  font-size: 36px !important;
}

.intro-text {
  font-size: 18px;
  line-height: 2;
  margin-bottom: 56px;
  max-width: 955px;
}

.intro-text a {
  text-decoration: underline;
}

.stepper {
  background: none;
}

.step-header {
  background: #3F44A60D;
  border-radius: 22px;
  box-shadow: none;
  justify-content: center;
  gap: 1rem 80px;
  height: 56px;
  margin-bottom: 72px;
}

.step-header :deep(.v-stepper__step--complete:not([disabled])) {
  cursor: pointer;
}

.stepper-items {
  margin: 1rem auto;
}

.stepper-items,
.stepper-items :deep(.v-stepper__wrapper) {
  overflow: visible;
  margin: 1rem auto;
}

.details-column {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.details-column > div {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.details-column :deep(.v-button) {
  margin-top: 37px;
}

.thanks-step {
  max-width: 750px;
  margin: auto;

  :deep(.prosk-title) {
    max-width: 600px;
    margin: 0 auto 56px;
    text-align: center;
  }
}

.thanks-footer {
  display: block;
  padding-bottom: 40px !important;
}
</style>
